<template>
  <section class="new_css">
    <el-row class="new_header">
      <el-col v-if="buttonList.length > 0"
              :span="24"
              class="organizetoolbar"
              style="padding-bottom: 0px">
        <el-form :inline="true"
                 ref="selectForm"
                 :model="selectForm"
                 class="select_form">
          <el-form-item>
            <el-input v-model="selectForm.Name"></el-input>
          </el-form-item>
          <toolbar :buttonList="buttonList"
                   @callFunction="callFunction"
                   :buttonListmsg="buttonListmsg"></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <!--列表-->
    <el-table :data="users"
              highlight-current-row
              @current-change="selectCurrentRow"
              class="new_table">
      <el-table-column type="index"></el-table-column>
      <el-table-column prop="username"
                       label="登录名"
                       align="center">
      </el-table-column>
      <el-table-column label="用户姓名"
                       prop="name"
                       align="center"></el-table-column>
      <el-table-column label="邮箱"
                       prop="Email"
                       align="center"></el-table-column>
      <el-table-column label="联系方式"
                       prop="phone"
                       align="center"></el-table-column>
      <!-- <el-table-column prop = "RoleNames" label = "角色名称" align="center">
                 <template slot-scope = "scope">
                    <el-tag v-for = "item in scope.row.RoleNames" :key = "item.Id" >{{item}}</el-tag>
                </template>
            </el-table-column> -->
      <el-table-column label="是否被锁定"
                       prop="status"
                       align="center">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status ? 'success' : 'danger'"
                  disable-transitions>
            {{ scope.row.LockoutEnabled ? "是" : "否" }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="锁定时间"
                       prop="LockoutEnd"
                       :formatter="formatCreateTime"
                       align="center"></el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination align="center"
                   @current-change="handleCurrentChange"
                   :current-page="currentPage"
                   :page-size="pageSize"
                   layout="prev, pager, next, jumper"
                   :page-count="total"
                   :total="totaldata">
    </el-pagination>
    <!--编辑界面-->
    <el-dialog title="编辑"
               :visible.sync="editFormVisible"
               v-model="editFormVisible"
               :close-on-click-modal="false">
      <el-form :model="editForm"
               label-width="80px"
               :rules="editFormRules"
               ref="editForm">
        <el-form-item label="登录名"
                      prop="UserName">
          <el-input v-model="editForm.UserName"
                    auto-complete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="用户姓名"
                      prop="UserNameClaim">
          <el-input v-model="editForm.UserNameClaim"
                    auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="邮箱"
                      prop="Email">
          <el-input v-model="editForm.Email"
                    auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="联系方式"
                      prop="PhoneNumber">
          <el-input v-model="editForm.PhoneNumber"
                    auto-complete="off"></el-input>
        </el-form-item>
        <!--<el-form-item label="是否锁定"
                      prop="LockoutEnabled"
                      disabled>
          <el-switch v-model="editForm.LockoutEnabled"> </el-switch>
        </el-form-item>
        <el-form-item label="锁定时间"
                      prop="LockoutEnd">
          <el-date-picker v-model="editForm.LockoutEnd"
                          type="date"
                          value-format="yyyy-MM-dd"
                          placeholder="请选择日期"
                          disabled></el-date-picker>
        </el-form-item>-->
        <!-- <el-form-item prop="RIds" label="角色">
                    <el-select style="width: 100%;" multiple v-model = "editForm.RIds" placeholder="请选择角色">
                    <el-option v-for = "item in Rolemodules" :key = "item.Id" :value = "item.Id" :label = "item.Name">
                    </el-option>
                    <div style="bottom: 0;width: 100%;background: #fff">
                        <el-pagination align='center' @current-change="RoleCurrentChange" :current-page="RolecurrentPage" :page-size="RolepageSize" layout="prev, pager, next, jumper" :page-count="Roletotal" :total="Roletotaldata">
                        </el-pagination>
                        </div>
                 </el-select>
                </el-form-item> -->
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click.native="editFormVisible = false">取消</el-button>
        <el-button type="primary"
                   @click.native="editSubmit">提交</el-button>
      </div>
    </el-dialog>

    <!--新增界面-->
    <el-dialog title="新增"
               :visible.sync="addFormVisible"
               v-model="addFormVisible"
               :close-on-click-modal="false">
      <el-form :model="addForm"
               label-width="80px"
               :rules="addFormRules"
               ref="addForm">
        <el-form-item label="登录名"
                      prop="UserName">
          <el-input v-model="addForm.UserName"
                    auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="用户姓名"
                      prop="UserNameClaim">
          <el-input v-model="addForm.UserNameClaim"
                    auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="邮箱"
                      prop="Email">
          <el-input v-model="addForm.Email"
                    auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="联系方式"
                      prop="PhoneNumber">
          <el-input v-model="addForm.PhoneNumber"
                    auto-complete="off"></el-input>
        </el-form-item>
        <!--<el-form-item label="是否锁定"
                      prop="LockoutEnabled">
          <el-switch v-model="addForm.LockoutEnabled"
                     disabled> </el-switch>
        </el-form-item>
        <el-form-item label="锁定时间"
                      prop="LockoutEnd">
          <el-date-picker v-model="addForm.LockoutEnd"
                          type="date"
                          value-format="yyyy-MM-dd"
                          placeholder="请选择日期"
                          disabled></el-date-picker>
        </el-form-item>-->
        <!-- <el-form-item prop="RIds" label="角色">
                    <el-select style="width: 100%;" multiple v-model = "addForm.RIds" placeholder="请选择角色">
                    <el-option v-for = "item in Rolemodules" :key = "item.Id" :value = "item.Id" :label = "item.Name">
                    </el-option>
                    <div style="bottom: 0;width: 100%;background: #fff">
                        <el-pagination align='center' @current-change="RoleCurrentChange" :current-page="RolecurrentPage" :page-size="RolepageSize" layout="prev, pager, next, jumper" :page-count="Roletotal" :total="Roletotaldata">
                        </el-pagination>
                        </div>
                    </el-select>
                </el-form-item> -->
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click.native="addFormVisible = false">取消</el-button>
        <el-button type="primary"
                   @click.native="addSubmit">提交</el-button>
      </div>
    </el-dialog>
    <!--设置密码界面-->
    <el-dialog title="设置密码"
               :visible.sync="passwordFormVisible"
               v-model="passwordFormVisible"
               :close-on-click-modal="false">
      <el-form :model="passwordForm"
               label-width="80px"
               :rules="passwordFormRules"
               ref="passwordForm">
        <el-form-item label="登录名"
                      prop="UserName">
          <el-input v-model="passwordForm.UserName"
                    auto-complete="off"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="密码"
                      prop="Password">
          <el-input v-model="passwordForm.Password"
                    auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码"
                      prop="ConfirmedPassword">
          <el-input v-model="passwordForm.ConfirmedPassword"
                    auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click.native="passwordFormVisible = false">取消</el-button>
        <el-button type="primary"
                   @click="passwordSubmit">提交</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import util from "../../../util/date";
import {
  testapi,
  getUserListPage,
  getRoleListPage,
  removeUser,
  batchRemoveUser,
  editUser,
  addUser,
        SetPassword,
        putUpdateUserUnLockById,    // 解锁用户
        putUpdateUserLock           // 锁定用户
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar },
  data () {
    return {
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 10, // 每页的数据条数
      page: 1,
      RolecurrentPage: 1, // 角色当前页码
      Roletotal: null, // 角色总条数
      Roletotaldata: null,
      RolepageSize: 10, // 角色每页的数据条数
      Rolepage: 1,
      searchVal: "",
      selectForm: {
        name: "",
      },
      users: [],
      roles: [],
      Rolemodules: [],
      buttonList: [],
      buttonListmsg: "users",
      editFormVisible: false, //编辑界面是否显示
      editFormRules: {
        UserName: [
          { required: true, message: "请输入登录名", trigger: "blur" },
        ],
      },
      //编辑界面数据
      editForm: {
        Id: 0,
        UserName: null,
        Email: null,
        PhoneNumber: null,
        //    RIds: null,
        EmailConfirmed: null,
        PhoneNumberConfirmed: null,
        UserNameClaim: null,
        LockoutEnabled: null,
        LockoutEnd: "",
      },

      addFormVisible: false, //新增界面是否显示
      addFormRules: {
        UserName: [
          { required: true, message: "请输入登录名", trigger: "blur" },
        ],
      },
      //新增界面数据
      addForm: {
        UserName: null,
        Email: null,
        PhoneNumber: null,
        //    RIds: null,
        EmailConfirmed: null,
        PhoneNumberConfirmed: null,
        UserNameClaim: null,
        LockoutEnabled: null,
        LockoutEnd: "",
      },
      passwordFormRules: {
        Password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        ConfirmedPassword: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
        ],
      },
      passwordFormVisible: false, //设置密码页面显示
      passwordForm: {
        //设置密码表单初始化
        UserName: null,
        Password: null,
        ConfirmedPassword: null,
      },
    };
  },
  methods: {
    selectCurrentRow (val) {
      this.currentRow = val;
    },
    callFunction (item) {
      this.filters = {
        name: item.search,
      };
      this[item.Func].apply(this, item);
    },
    formatCreateTime: function (row, colume) {
      return !row.LockoutEnd || row.LockoutEnd == ""
        ? ""
        : util.formatDate.format(new Date(row.LockoutEnd), "yyyy-MM-dd");
    },
    handleCurrentChange (val) {
      this.page = val;
      this.getUsers();
    },
    //获取用户列表
    getUsers () {
      let para = {
        hasJob:1,
        isAllNextDept: false,
        pageNum: this.page,
        pageSize: this.pageSize,
        name: this.selectForm.Name,
      };
      getUserListPage(para).then((res) => {
        this.total = res.data.data.pages;
        this.totaldata = res.data.data.total;
        this.users = res.data.data.list;
      });
      },
    // 锁定解锁用户
      onUpdateUserLock() {
          let row = this.currentRow;
          if (!row) {
              this.$message({
                  message: "请选择要操作的一行数据！",
                  type: "error",
              });
              return;
          }
          let optstring = "";
          if (row.LockoutEnabled)
              optstring = "解锁";
          else
              optstring = "锁定";
          this.$confirm("确认" + optstring+"该记录吗?", "提示", {
              type: "warning",
          })
              .then(() => {
                  
                  // 当前行为锁定需要解锁
                  if (row.LockoutEnabled) {
                      let para = { id: row.Id };
                      putUpdateUserUnLockById(para).then((res) => {
                          if (util.isEmt.format(res)) {
                              return;
                          }
                          if (res.data.success) {
                              this.$message({
                                  message: "解锁成功",
                                  type: "success",
                              });
                          } else {
                              this.$message({
                                  message: res.data.msg,
                                  type: "error",
                              });
                          }

                          this.getUsers();
                      });
                  }
                  else // 当前未锁定需要锁定
                  {
                      let para = { "id": row.Id };
                      putUpdateUserLock(para).then((res) => {
                          if (util.isEmt.format(res)) {
                              return;
                          }
                          if (res.data.success) {
                              this.$message({
                                  message: "锁定成功",
                                  type: "success",
                              });
                          } else {
                              this.$message({
                                  message: res.data.msg,
                                  type: "error",
                              });
                          }

                          this.getUsers();
                      });
                  }
                  
              })
              .catch(() => { });
          
      },
    RoleCurrentChange (val) {
      this.Rolepage = val;
      // this.getAllRoles();
    },
    // //获取全部角色
    // getAllRoles(){
    //    let para = {
    //    page: this.Rolepage,
    //    size: this.RolepageSize,
    //    };
    //    getRoleListPage(para).then((res) => {
    //         this.Roletotal = res.data.response.pageCount;
    //         this.Roletotaldata = res.data.response.dataCount;
    //         this.Rolemodules = res.data.response.data;
    //    });
    // },
    //删除
    handleDel () {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要删除的一行数据！",
          type: "error",
        });
        return;
      }
      this.$confirm("确认删除该记录吗?", "提示", {
        type: "warning",
      })
        .then(() => {
          let para = { id: row.Id };
          removeUser(para).then((res) => {
            if (util.isEmt.format(res)) {
              return;
            }
            if (res.data.success) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }

            this.getUsers();
          });
        })
        .catch(() => { });
    },
    //显示编辑界面
    handleEdit () {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      this.editFormVisible = true;
      // this.getAllRoles();
      this.editForm = Object.assign({}, row);
    },
    //显示新增界面
    handleAdd () {
      this.addFormVisible = true;
      // this.getAllRoles();
      this.addForm = {
        UserName: null,
        Email: null,
        PhoneNumber: null,
        RIds: null,
      };
    },
    //编辑
    editSubmit: function () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            let para = Object.assign({}, this.editForm);
            para.EmailConfirmed = this.checkEmail(para.Email);
            para.PhoneNumberConfirmed = this.checkPhone(para.PhoneNumber);
            //if (
            //  para.EmailConfirmed == false ||
            //  para.PhoneNumberConfirmed == false
            //) {
            //  this.$message({
            //    message: "邮箱或联系方式格式错误，请填写有效邮箱或联系方式",
            //    type: "error",
            //  });
            //} else
            {
              editUser(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: res.data.msg,
                    type: "success",
                  });
                  this.$refs["editForm"].resetFields();
                  this.editFormVisible = false;
                  this.getUsers();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
              });
            }
          });
        }
      });
    },
    //新增
    addSubmit: function () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            let para = Object.assign({}, this.addForm);
            para.EmailConfirmed = this.checkEmail(para.Email);
            para.PhoneNumberConfirmed = this.checkPhone(para.PhoneNumber);
            if (
              para.EmailConfirmed == false ||
              para.PhoneNumberConfirmed == false
            ) {
              this.$message({
                message: "邮箱或联系方式格式错误，请填写有效邮箱或联系方式",
                type: "error",
              });
            } else {
              addUser(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: res.data.msg,
                    type: "success",
                  });
                  this.$refs["addForm"].resetFields();
                  this.addFormVisible = false;
                  this.getUsers();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
              });
            }
          });
        }
      });
    },
    handlePassword () {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      this.passwordFormVisible = true;
      this.passwordForm = Object.assign({}, row);
    },
    //设置密码提交
    passwordSubmit () {
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          let para = Object.assign({}, this.passwordForm);
          SetPassword(para).then((res) => {
            if (res.data.success) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.$refs["passwordForm"].resetFields();
              this.passwordFormVisible = false;
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          });
        }
      });
    },
    getButtonList2 (routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleCurrentChange (val) {
      this.currentPage = val;
      this.page = val;
      this.getUsers();
    },
    //判断用户输入的电子邮箱格式是否正确
    checkEmail (EM) {
      var email = EM;
      if (
        !/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(
          email
        )
      ) {
        return false;
      } else {
        return true;
      }
    },
    //判断联系方式输入格式
    checkPhone (DEL) {
      var delnum = DEL;
      if (!/^(((13[0-9]{1})|(15[0-9]{1})|(18[0-9]{1}))+\d{8})$/.test(delnum)) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted () {
    this.getUsers();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    //第一种写法，每个页面都需要写方法，但是可以做特性化处理
    // this.getButtonList(routers);

    //第二种写法，封装到 permissionRouter.js 中
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
.el-table__body tr.current-row > td {
  background: #fabe64 !important;
}
</style>
